import { React, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Timeslotter from '../appointment/Timeslotter'
import { formatInTZ } from '../../../utils/date-format'
import PageLayout from '../../../layouts/pageLayout/pageLayout'
import FormGroup from './components/formGroup'
import { AppointmentsReference } from '../../../services/DatabaseService'
import './style.css'

const Form = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [errorMssg, setErrorMssg] = useState('')

  const handleSubmit = async event => {
    event.preventDefault()
    setErrorMssg('')
    if (
      firstname.trim() === '' ||
      lastname.trim() === '' ||
      phoneNumber.trim() === '' ||
      email.trim() === ''
    ) {
      setErrorMssg('Fyll i alla obligatoriska fält.')
      return
    }

    // check if time slot is taken
    try {
      const appointments = await AppointmentsReference.getAllWhere(
        'date',
        '==',
        location.state.date
      )
      const bookedSlots = []
      appointments.forEach(appt => {
        const apptDate = formatInTZ(appt.date.toDate(), 'yyyy-MM-dd')
        const slotStart = `${apptDate}T${appt.slot.start}:00`
        const slotEnd = `${apptDate}T${appt.slot.end}:00`
        bookedSlots.push({ start: slotStart, end: slotEnd })
      })
      const timeSlotter = new Timeslotter(
        location.state.date,
        location.state.slot.start,
        location.state.slot.end
      )
      const isBooked = timeSlotter.checkForDoubleBooking(bookedSlots)

      if (isBooked) {
        setErrorMssg(
          'Den valda tiden är inte tillgänglig längre. Vänligen välj en annan tid.'
        )
      } else {
        addToDatabase()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addToDatabase = async () => {
    const data = {
      createdTime: new Date(),
      serviceId: location.state.service.id,
      date: location.state.date,
      bookedBy: 1,
      slot: {
        start: location.state.slot.start,
        end: location.state.slot.end,
      },
      client: {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phoneNumber: phoneNumber,
      },
    }
    try {
      await AppointmentsReference.create(data)
      onSuccess()
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const onSuccess = () => {
    navigate('/boka/bekraftelse', {
      state: {
        date: location.state.date,
        slot: {
          start: location.state.slot.start,
          end: location.state.slot.end,
        },
      },
    })
  }

  return (
    <PageLayout title={'DIN INFORMATION'}>
      <div
        className={`error-message ${
          errorMssg.trim() === '' ? 'hide-mssg' : 'show-mssg'
        }`}
      >
        {errorMssg}
      </div>
      <form className='form'>
        <div className='form-box'>
          <FormGroup title={'Förnamn'}>
            <input
              type='text'
              className='form__input'
              placeholder='Förnamn'
              id='Förnamn'
              value={firstname}
              onChange={e => setFirstname(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup title={'Efternamn'}>
            <input
              type='text'
              className='form__input'
              placeholder='Efternamn'
              id='Efternamn'
              value={lastname}
              onChange={e => setLastname(e.target.value)}
              required
            />
          </FormGroup>
        </div>
        <div className='form-box'>
          <FormGroup title={'Mobil'}>
            <input
              type='tel'
              className='form__input'
              placeholder='Mobil'
              id='Mobil'
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup title={'E-post'}>
            <input
              type='email'
              className='form__input'
              placeholder='E-post'
              id='E-post'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className='form-btn-box'>
          <div className='form__group'>
            <button className='form-btn' onClick={handleSubmit}>
              BEKRÄFTA BOKNING
            </button>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default Form
