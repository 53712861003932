import React from 'react'
import { useNavigate } from 'react-router-dom'
import Service from './components/service/service'
import './style.css'
import { useEffect } from 'react'
import { auth } from '../../../../firebase'
import { BsPlusLg } from 'react-icons/bs'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import HeaderButton from '../../../../components/headerButton/headerButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchServices,
  removeService,
} from '../../../../redux/actions/servicesActions'

const AdminServices = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.services.loading)
  const services = useSelector(state => state.services.data)

  useEffect(() => {
    dispatch(fetchServices())
    const signIn = () => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (!user) {
          navigate('/admin/signin')
        }
      })
      return () => unsubscribe()
    }
    signIn()
  }, [dispatch, navigate])

  const onClickNewService = () => {
    navigate('/admin/services/new')
  }

  const onHandleDeletion = id => {
    dispatch(removeService(id))
  }

  const onHandleEdit = serviceId => {
    navigate(`/admin/services/edit/${serviceId}`)
  }

  return (
    <PageLayout
      title={'TJÄNSTER'}
      admin={true}
      headerButton={
        <HeaderButton
          title={'Ny tjänst'}
          icon={<BsPlusLg className='header-btn-icon' />}
          onClick={onClickNewService}
        />
      }
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className='u-display-flex u-flex-direction-column'>
          {services.map(service => (
            <Service
              key={service.id}
              service={service}
              handleDelete={onHandleDeletion}
              handleEdit={onHandleEdit}
            />
          ))}
        </div>
      )}
    </PageLayout>
  )
}

export default AdminServices
