import { createSlice } from '@reduxjs/toolkit'

const hoursSlice = createSlice({
  name: 'hours',
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  reducers: {
    hoursLoading(state, _) {
      state.loading = true
      state.error = null
    },
    hoursReceived(state, action) {
      state.loading = false
      state.data = action.payload
    },
    hoursFailed(state, action) {
      state.loading = false
      state.error = action.payload
    },
    hourUpdate(state, action) {
      const { id, data } = action.payload
      const index = state.data.findIndex(hr => hr.id === id)
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...data }
      }
    },
  },
})

export const { hoursLoading, hoursReceived, hoursFailed, hourUpdate } =
  hoursSlice.actions
export default hoursSlice.reducer
