import Toggle from 'react-styled-toggle'

const Switch = ({ value, label, onChange }) => {
  return (
    <Toggle
      value={value}
      checked={value}
      labelRight={label}
      onChange={onChange}
      backgroundColorChecked={'var(--primary-color)'}
      width={50}
      height={30}
      sliderHeight={24}
      sliderWidth={24}
      translate={18}
    />
  )
}

export default Switch
