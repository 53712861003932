import { React, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import { auth } from '../../firebase'
import AdminAppointments from './pages/appointments/appointments'
import AdminServices from './pages/services/services'
import AdminSignIn from './pages/authentication/signIn'
import ServicePage from './pages/newService/servicePage'
import Hours from './pages/hours/hours'
import EditHour from './pages/editHour/editHour'
import NewAppointment from './pages/newAppt/newAppointment'

const Admin = () => {
  const [isUserSignedIn, setIsUserSignedIn] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        setIsUserSignedIn(true)
      } else {
        setIsUserSignedIn(false)
      }
    })
  }, [])

  return (
    <>
      <Routes>
        <Route
          path='/'
          element={!isUserSignedIn ? <AdminSignIn /> : <AdminAppointments />}
        />
        <Route path='/signin' element={<AdminSignIn />} />
        <Route path='/appointments' element={<AdminAppointments />} />
        <Route path='/appointments/new' element={<NewAppointment />} />
        <Route path='/hours' element={<Hours />} />
        <Route path='/hours/:hourId' element={<EditHour />} />
        <Route path='/services' element={<AdminServices />} />
        <Route
          path='/services/new'
          element={<ServicePage key={new Date()} />}
        />
        <Route path='/services/edit/:serviceId' element={<ServicePage />} />
      </Routes>
    </>
  )
}

export default Admin
