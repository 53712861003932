import React from 'react'
import { useNavigate } from 'react-router-dom'
import { logo_img } from '../../utils/constants'
import './style.css'

const Navbar = () => {
  const navigate = useNavigate()

  const navigateHome = () => {
    navigate('/')
  }

  return (
    <nav>
      <div className='container u-text-align-center'>
        <div className='logo-box'>
          <img
            className='nav-logo'
            src={logo_img}
            alt='FadezMDot'
            onClick={navigateHome}
          />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
