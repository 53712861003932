import React from 'react'
import Testimonial from './testimonial'
import { testimonials } from '../../../utils/constants'

const Testimonials = () => {
  return (
    <div className='reviews-section u-margin-top-huge'>
      <div className='container'>
        <h2 className='section-title u-text-align-center u-margin-bottom-big'>
          VAD VÅRA KUNDER SÄGER!
        </h2>
        <div className='box-container u-display-flex'>
          {testimonials.map(t => (
            <Testimonial
              key={t.name + t.testimonial}
              testimonial={t.testimonial}
              name={t.name}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Testimonials
