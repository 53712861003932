import React from 'react'

const Testimonial = ({ testimonial, name }) => {
  return (
    <div className='box u-flex-1 u-text-align-center'>
      <p className='review-text u-margin-bottom-small'>{testimonial}</p>
      <p className='review-author'>{name}</p>
    </div>
  )
}

export default Testimonial
