import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import TimeSelector from './components/timeSelector/timeSelector'
import Switch from './components/switch/switch'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateHour } from '../../../../redux/actions/hoursActions'

const EditHour = () => {
  const { hourId } = useParams()
  const navigate = useNavigate()
  const [openTime, setOpenTime] = useState(null)
  const [closeTime, setCloseTime] = useState(null)
  const [isClosed, setIsClosed] = useState(true)
  const hours = useSelector(state => state.hours.data)
  const dispatch = useDispatch()

  useEffect(() => {
    const hour = hours.filter(hr => hr.id === hourId)[0]
    if (hour.open !== 'closed' && hour.close !== 'closed') {
      const formattedDate = format(new Date(), 'yyyy-MM-dd')
      const openDate = new Date(`${formattedDate}T${hour.open}`)
      const closeDate = new Date(`${formattedDate}T${hour.close}`)
      setOpenTime(openDate)
      setCloseTime(closeDate)
      setIsClosed(false)
    } else {
      setIsClosed(true)
    }
  }, [hourId, hours])

  const handleChangeToggle = () => {
    setIsClosed(!isClosed)
  }

  const handleChangeOpenTime = time => {
    setOpenTime(time)
  }

  const handleChangeCloseTime = time => {
    setCloseTime(time)
  }

  const handleSave = async () => {
    let openingHour = {}

    if (openTime === null || closeTime === null) {
      console.log('Cannot leave input empty')
      return
    }
    if (!isClosed) {
      openingHour = {
        open: format(openTime, 'HH:mm'),
        close: format(closeTime, 'HH:mm'),
      }
      if (openTime >= closeTime) {
        console.log('start time cannot be after end time')
        return
      }
    } else {
      openingHour = { open: 'closed', close: 'closed' }
    }
    dispatch(updateHour(hourId, openingHour))
    navigate('/admin/hours')
  }

  return (
    <PageLayout title={hourId.toUpperCase()} admin={true}>
      <div className='time-container'>
        <div className='switch-box'>
          <Switch
            value={!isClosed}
            label={!isClosed ? 'Öppet' : 'Stängt'}
            onChange={handleChangeToggle}
          />
        </div>
        <div
          className={`time-selector-box time-selector-box-${
            !isClosed ? 'show' : 'hide'
          }`}
        >
          <TimeSelector
            label={'Start'}
            selected={openTime}
            onChange={handleChangeOpenTime}
          />
          <TimeSelector
            label={'Slut'}
            selected={closeTime}
            onChange={handleChangeCloseTime}
          />
        </div>
        <div className='save-btn-box'>
          <button className='save-btn' onClick={handleSave}>
            Spara
          </button>
        </div>
      </div>
    </PageLayout>
  )
}

export default EditHour
