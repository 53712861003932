import React from 'react'
import { masthead, logo_img } from '../../../utils/constants'

const Masthead = ({ onClick }) => {
  return (
    <div className='masthead-section'>
      <div className='container'>
        <div className='masthead-content u-text-align-center'>
          <img
            className='primary-logo u-margin-bottom-small'
            src={logo_img}
            alt='FadezMdot'
          />
          <h1 className='heading-text u-margin-bottom-small'>
            {masthead.title}
          </h1>
          <p className='subheading-text u-margin-bottom-medium'>
            {masthead.subtitle}
          </p>
          <button className='cta-btn' onClick={onClick}>
            {masthead.cta}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Masthead
