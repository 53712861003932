import { formatInTimeZone } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

export const formatInTZ = (date, fmt) => {
  const timeZone = 'Europe/Stockholm'
  return formatInTimeZone(date, timeZone, fmt, { locale: sv })
}

export const isToday = date => {
  const dateFormat = 'yyyy-MM-dd'
  const today = formatInTZ(new Date(), dateFormat)
  const formattedDate = formatInTZ(date, dateFormat)
  return today === formattedDate
}
