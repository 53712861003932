const FormGroup = ({ title, children }) => {
  return (
    <div className='form__group'>
      <label htmlFor={title} className='form__label'>
        * {title}
      </label>
      {children}
    </div>
  )
}

export default FormGroup
