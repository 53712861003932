import React from 'react'
import { InstagramLogo, TiktokLogo } from '@phosphor-icons/react'
import { socialLinks } from '../../../utils/constants'

const Footer = () => {
  return (
    <div className='footer u-margin-top-huge'>
      <div className='container u-text-align-center'>
        <div className='menu-box_container u-display-flex u-justify-content-center'>
          <div className='menu-box'>
            <a
              className='menu-box_link'
              href={socialLinks.instagram}
              target='_blank'
              rel='noreferrer'
            >
              <InstagramLogo size={42} />
            </a>
          </div>
          <div className='menu-box'>
            <a
              className='menu-box_link'
              href={socialLinks.tiktok}
              target='_blank'
              rel='noreferrer'
            >
              <TiktokLogo size={42} />
            </a>
          </div>
        </div>
        <div id='copyright-container'>
          <p id='copyright-container_text'>
            © 2023 Fadezmdot | Hemsidan byggd av{' '}
            <span>
              <a
                id='nordify-link'
                href='https://www.nordifyagency.se/'
                target='_blank'
                rel='noreferrer'
              >
                Nordify
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
