import { React, useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Appointment from './components/appointment/appointment'
import { auth } from '../../../../firebase'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './style.css'
import { BsPlusLg } from 'react-icons/bs'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import HeaderButton from '../../../../components/headerButton/headerButton'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../../../../redux/actions/servicesActions'
import {
  fetchAdminAppointments,
  removeAppointment,
} from '../../../../redux/actions/appointmentsActions'

const AdminAppointments = () => {
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const isLoadingServices = useSelector(state => state.services.loading)
  const isLoadingAppts = useSelector(state => state.appointments.loading)
  const services = useSelector(state => state.services.data)
  const appointments = useSelector(state => state.appointments.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchServices())
    dispatch(fetchAdminAppointments(selectedDate, '=='))
    const signIn = () => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (!user) {
          navigate('/admin/signin')
        }
      })
      return () => unsubscribe()
    }
    signIn()
  }, [dispatch, navigate, selectedDate])

  const onClickNewAppt = () => {
    navigate('/admin/appointments/new')
  }

  const onApptCancel = async apptId => {
    dispatch(removeAppointment(apptId))
  }

  const handleDateChange = date => {
    setSelectedDate(date)
    dispatch(fetchAdminAppointments(selectedDate, '=='))
  }

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='custom-input-box'>
      <p className='custom-input-title'>Välj datum:</p>
      <button className='custom-input' onClick={onClick} ref={ref}>
        {value}
      </button>
    </div>
  ))

  return (
    <PageLayout
      title={'BOKNINGAR'}
      admin={true}
      headerButton={
        <HeaderButton
          title={'Ny Bokning'}
          icon={<BsPlusLg className='header-btn-icon' />}
          onClick={onClickNewAppt}
        />
      }
    >
      <DatePicker
        customInput={<CustomInput />}
        closeOnScroll={true}
        selected={selectedDate}
        onChange={handleDateChange}
      />
      <div className='appointments-list-container'>
        {isLoadingServices && isLoadingAppts ? (
          <div className='loading'>Loading...</div>
        ) : (
          appointments.map(appt => (
            <Appointment
              key={appt.id}
              appointment={appt}
              serviceName={
                services.filter(s => s.id === appt.serviceId)[0].name
              }
              handleApptCancelation={onApptCancel}
            />
          ))
        )}
      </div>
    </PageLayout>
  )
}

export default AdminAppointments
