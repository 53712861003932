import React, { forwardRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { auth } from '../../../../firebase'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import FormGroup from '../../../boka/form/components/formGroup'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../../../../redux/actions/servicesActions'
import { AppointmentsReference } from '../../../../services/DatabaseService'

const NewAppointment = () => {
  const navigate = useNavigate()
  const [selectedServiceId, setSelectedServiceId] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTime, setSelectedTime] = useState(null)
  const client = useState({})
  const services = useSelector(state => state.services.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchServices())
    const signIn = () => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (!user) {
          navigate('/admin/signin')
        }
      })
      return () => unsubscribe()
    }
    signIn()
  }, [dispatch, navigate])

  const handleServiceChange = event => {
    setSelectedServiceId(event.target.value)
  }

  const handleDateChange = date => {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    setSelectedDate(date)
  }

  const handleTimeChange = time => {
    setSelectedTime(time)
  }

  const handleClientInfoChnage = event => {
    if (event.target.id === 'client-firstname') {
      client['firstname'] = event.target.value
    }
    if (event.target.id === 'client-lastname') {
      client['lastname'] = event.target.value
    }
    if (event.target.id === 'client-tel') {
      client['phoneNumber'] = event.target.value
    }
    if (event.target.id === 'client-email') {
      client['email'] = event.target.value
    }
  }

  const handleButtonClick = () => {
    if (selectedServiceId === '' || selectedTime === null) {
      return
    }

    if (
      client.firstname === undefined ||
      client.lastname === undefined ||
      client.phoneNumber === undefined ||
      client.email === undefined
    ) {
      return
    }

    if (
      client.firstname.trim() === '' ||
      client.lastname.trim() === '' ||
      client.phoneNumber.trim() === '' ||
      client.email.trim() === ''
    ) {
      return
    }

    const selectedService = services.filter(
      sv => sv.id === selectedServiceId
    )[0]
    const endTime = new Date(
      selectedTime.getTime() + selectedService.duration * 60 * 1000
    )
    const slotStart = format(selectedTime, 'HH:mm')
    const slotEnd = format(endTime, 'HH:mm')

    const appointment = {
      createdTime: new Date(),
      serviceId: selectedServiceId,
      date: selectedDate,
      bookedBy: 0,
      slot: {
        start: slotStart,
        end: slotEnd,
      },
      client: {
        firstname: client.firstname,
        lastname: client.lastname,
        email: client.email,
        phoneNumber: client.phoneNumber,
      },
    }
    addToDatabase(appointment)
  }

  const addToDatabase = async appointment => {
    try {
      await AppointmentsReference.create(appointment)
      navigate('/admin/appointments')
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const DateAndTimeInput = forwardRef(({ value, onClick }, ref) => (
    <button className='date-time-cutom-input' onClick={onClick} ref={ref}>
      {value === '' ? 'Välj tid' : value}
    </button>
  ))

  return (
    <PageLayout title={'NY BOKNING'} admin={true}>
      <div className='form'>
        <div className='form-box'>
          <FormGroup title={'Välj tjänst'}>
            <select
              name='services'
              className='form__input'
              onChange={handleServiceChange}
            >
              <option value=''>Välj tjänst</option>
              {services.map(service => (
                <option key={service.id} value={service.id}>
                  {service.name}
                </option>
              ))}
            </select>
          </FormGroup>
          <FormGroup title={'Välj datum'}>
            <DatePicker
              customInput={<DateAndTimeInput />}
              closeOnScroll={true}
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date()}
            />
          </FormGroup>
          <FormGroup title={'Välj tid'}>
            <DatePicker
              customInput={<DateAndTimeInput />}
              selected={selectedTime}
              showTimeSelect
              showTimeSelectOnly
              timeFormat='HH:mm'
              timeIntervals={15}
              timeCaption='Tid'
              dateFormat='HH:mm'
              onChange={handleTimeChange}
            />
          </FormGroup>
        </div>
      </div>
      <form className='form client-form'>
        <div className='form-box'>
          <FormGroup title={'Förnamn'}>
            <input
              type='text'
              className='form__input'
              placeholder='Förnamn'
              id='client-firstname'
              value={client.firstname}
              onChange={handleClientInfoChnage}
              required
            />
          </FormGroup>
          <FormGroup title={'Efternamn'}>
            <input
              type='text'
              className='form__input'
              placeholder='Efternamn'
              id='client-lastname'
              value={client.lastname}
              onChange={handleClientInfoChnage}
              required
            />
          </FormGroup>
        </div>
        <div className='form-box'>
          <FormGroup title={'Mobil'}>
            <input
              type='tel'
              className='form__input'
              placeholder='Mobil'
              id='client-tel'
              value={client.phoneNumber}
              onChange={handleClientInfoChnage}
              required
            />
          </FormGroup>
          <FormGroup title={'E-post'}>
            <input
              type='email'
              className='form__input'
              placeholder='E-post'
              id='client-email'
              value={client.email}
              onChange={handleClientInfoChnage}
            />
          </FormGroup>
        </div>
      </form>
      <div className='new-appt-btn-box'>
        <button className='new-appt-btn' onClick={handleButtonClick}>
          Lägg till
        </button>
      </div>
    </PageLayout>
  )
}

export default NewAppointment
