import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Service from './components/service'
import PageLayout from '../../../layouts/pageLayout/pageLayout'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../../../redux/actions/servicesActions'

const Services = () => {
  const navigate = useNavigate()
  const isLoading = useSelector(state => state.services.loading)
  const services = useSelector(state => state.services.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchServices())
  }, [dispatch])

  function handleClickBook(service) {
    console.log(`service: ${service}`)
    navigate('/boka/tider', { state: { service: service } })
  }

  return (
    <PageLayout title={'VÄLJ TJÄNST'}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className='u-display-flex u-flex-direction-column'>
          {services.map(service => (
            <Service
              key={service.id}
              service={service}
              onClick={handleClickBook}
            />
          ))}
        </div>
      )}
    </PageLayout>
  )
}

export default Services
