import { formatInTZ, isToday } from '../../../utils/date-format'

class TimeSlotter {
  constructor(date, start, end, serviceDuration) {
    this.date = date
    this.start = start
    this.end = end
    this.serviceDuration = serviceDuration
  }

  getPossibleTimeSlots() {
    const timeSlots = []
    const interval = this.serviceDuration
    const formattedDate = formatInTZ(this.date, 'yyyy-MM-dd')
    const startTime = new Date(`${formattedDate}T${this.start}`)
    const endTime = new Date(`${formattedDate}T${this.end}`)

    while (startTime.getTime() + interval * 60 * 1000 <= endTime) {
      const formattedTime = formatInTZ(startTime, 'HH:mm')
      timeSlots.push({ slot: formattedTime, isAvailable: true })
      startTime.setTime(startTime.getTime() + interval * 60 * 1000)
    }

    return timeSlots
  }

  getAvailableTimeSlots(timeSlots, bookedSlots) {
    const date = formatInTZ(this.date, 'yyyy-MM-dd')

    timeSlots.forEach(timeSlot => {
      const slotStart = new Date(`${date}T${timeSlot.slot}`)
      const slotEnd = new Date(
        slotStart.getTime() + this.serviceDuration * 60 * 1000
      )

      if (isToday(this.date) && slotStart <= new Date()) {
        timeSlot.isAvailable = false
        return
      }

      for (const bookedSlot of bookedSlots) {
        const bookedSlotStart = new Date(bookedSlot.start)
        const bookedSlotEnd = new Date(bookedSlot.end)
        if (
          (slotStart >= bookedSlotStart && slotStart < bookedSlotEnd) ||
          (slotEnd > bookedSlotStart && slotEnd <= bookedSlotEnd)
        ) {
          timeSlot.isAvailable = false
          break
        }
      }
    })

    return timeSlots
  }

  getTimeSlots(appointments) {
    const timeSlots = this.getPossibleTimeSlots()
    const bookedSlots = []
    appointments.forEach(appt => {
      const apptDate = formatInTZ(appt.date.toDate(), 'yyyy-MM-dd')
      const slotStart = `${apptDate}T${appt.slot.start}:00`
      const slotEnd = `${apptDate}T${appt.slot.end}:00`
      bookedSlots.push({ start: slotStart, end: slotEnd })
    })
    return this.getAvailableTimeSlots(timeSlots, bookedSlots)
  }

  checkForDoubleBooking(bookedSlots) {
    let isBooked = false
    const date = formatInTZ(this.date, 'yyyy-MM-dd')
    const slotStart = new Date(`${date}T${this.start}`)
    const slotEnd = new Date(`${date}T${this.end}`)

    for (const slot of bookedSlots) {
      const bookedSlotStart = new Date(slot.start)
      const bookedSlotEnd = new Date(slot.end)

      if (
        (slotStart >= bookedSlotStart && slotStart < bookedSlotEnd) ||
        (slotEnd > bookedSlotStart && slotEnd <= bookedSlotEnd)
      ) {
        isBooked = true
      }
    }

    return isBooked
  }
}

export default TimeSlotter
