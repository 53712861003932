import './style.css'

const HeaderButton = ({ title, icon, onClick }) => {
  return (
    <button className='header-btn' onClick={onClick}>
      {icon}
      <span className='header-btn-title'>{title}</span>
    </button>
  )
}

export default HeaderButton
