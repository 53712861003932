import React from 'react'
import { IoClose } from 'react-icons/io5'
import { FiEdit } from 'react-icons/fi'

const Service = ({ service, handleDelete, handleEdit }) => {
  return (
    <div className='service-box u-display-flex u-justify-content-space-between'>
      <div className='service-info'>
        <h3 className='service-info__name'>{service.name}</h3>
        <div className='u-display-flex'>
          <p className='service-info_duration'>{service.duration} minuter</p>
          <p>,&nbsp;</p>
          <p className='service_info__price'>{service.price}</p>
        </div>
      </div>
      <div className='actions-list'>
        <button className='action-btn' onClick={() => handleEdit(service.id)}>
          <FiEdit className='action-btn-icon' />
        </button>
        <button className='action-btn' onClick={() => handleDelete(service.id)}>
          <IoClose className='action-btn-icon' />
        </button>
      </div>
    </div>
  )
}

export default Service
