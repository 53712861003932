import { AppointmentsReference } from '../../services/DatabaseService'
import {
  appointmentsLoading,
  appointmentsReceived,
  appointmentsFailed,
  appointmentDelete,
} from '../reducers/appointmentsReducer'

export const fetchClientAppointments = (date, operator) => async dispatch => {
  try {
    dispatch(appointmentsLoading())
    date.setHours(0, 0, 0, 0)
    const data = await AppointmentsReference.getAllWhere('date', operator, date)
    dispatch(appointmentsReceived(data))
  } catch (e) {
    dispatch(appointmentsFailed(e))
  }
}

export const fetchAdminAppointments = (date, operator) => async dispatch => {
  try {
    dispatch(appointmentsLoading())
    date.setHours(0, 0, 0, 0)
    const data = await AppointmentsReference.getAllWhereAndOrderBy(
      'date',
      operator,
      date,
      'slot'
    )
    dispatch(appointmentsReceived(data))
  } catch (e) {
    dispatch(appointmentsFailed(e))
  }
}

export const removeAppointment = apptID => async dispatch => {
  try {
    await AppointmentsReference.remove(apptID)
    dispatch(appointmentDelete({ id: apptID }))
  } catch (e) {
    console.log(e)
  }
}
