import { React } from 'react'

const Timeslot = ({ title, selected, available, onClick }) => {
  return (
    <div
      className={`timeslot ${selected ? 'selected' : ''} ${
        !available ? 'notAvailable' : ''
      }`}
      onClick={available ? onClick : null}
    >
      <h3>{title}</h3>
    </div>
  )
}

export default Timeslot
