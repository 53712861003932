import React from 'react'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'
import { googleMapsAPIKey, location } from '../../../utils/constants'

const Map = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsAPIKey,
  })
  const lat = location.position.lat
  const lng = location.position.lon

  if (!isLoaded) return <div>Loading...</div>

  return (
    <GoogleMap
      zoom={15}
      center={{ lat, lng }}
      mapContainerClassName='map-box_img'
      options={{
        styles: [
          {
            stylers: [
              { saturation: -100 },
              { gamma: 0.8 },
              { lightness: 4 },
              { visibility: 'on' },
            ],
          },
        ],
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      <MarkerF position={{ lat, lng }} />
    </GoogleMap>
  )
}

export default Map
