import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/index'
import Services from './pages/boka/services'
import Form from './pages/boka/form'
import Confirmation from './pages/boka/confirmation'
import AppointmentBookingPage from './pages/boka/appointment/appointment'
import AppointmentCancelPage from './pages/cancel'
import Admin from './pages/admin/admin'
import './styles/global.css'
import './styles/utilities.css'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/boka/tjanster' element={<Services />} />
        <Route path='/boka/tider' element={<AppointmentBookingPage />} />
        <Route path='/boka/form' element={<Form />} />
        <Route path='/boka/bekraftelse' element={<Confirmation />} />
        <Route path='/avboka' element={<AppointmentCancelPage />} />
        <Route path='/admin/*' element={<Admin />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
