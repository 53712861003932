import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import './style.css'

const TimeSelector = ({ label, selected, onChange }) => {
  return (
    <div className='time-selector'>
      <p className='time-selector-label'>{label}: </p>
      <DatePicker
        customInput={<CustomTimeInput />}
        selected={selected}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeFormat='HH:mm'
        timeIntervals={30}
        timeCaption='Tid'
        dateFormat='HH:mm'
      />
    </div>
  )
}

const CustomTimeInput = forwardRef(({ value, onClick }, ref) => (
  <div className='custom-time-input-box'>
    <button className='custom-time-input' onClick={onClick} ref={ref}>
      {value === '' ? 'Välj tid' : value}
    </button>
  </div>
))

export default TimeSelector
