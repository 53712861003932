import React from 'react'
import Map from './map'
import { location } from '../../../utils/constants'

const Contact = () => {
  return (
    <div className='contact-section u-margin-bottom-huge'>
      <div className='container u-display-flex'>
        <div className='map-box u-flex-5 u-overflow-hidden'>
          <Map />
        </div>
        <div className='contact-content u-flex-2 u-display-flex u-flex-direction-column'>
          <h3 className='contact-content_header'>Kontakt</h3>
          <p className='contact-content_subheader'>Besök oss</p>
          <p className='contact-content_text'>{location.address}</p>
          <p className='contact-content_text'>
            {location.postcode} {location.city}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Contact
