import { React, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { logo_img } from '../../utils/constants'
import menu from '../../assets/images/menu-icon.svg'
import { auth } from '../../firebase'
import './style.css'

const AdminNavbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMenuShown, setIsMenuShown] = useState(false)

  const navigateAdminHome = () => {
    navigate('/admin/')
  }

  const onClickAppointments = () => {
    navigate('/admin/appointments')
  }

  const onClickServices = () => {
    navigate('/admin/services')
  }

  const onClickHours = () => {
    navigate('/admin/hours')
  }

  const onClickSignOut = () => {
    auth.signOut()
  }

  const onClickMenu = () => {
    setIsMenuShown(!isMenuShown)
  }

  return (
    <div className='admin-nav'>
      <div className='admin-nav-container'>
        <div className='admin-nav-top'>
          <div className='admin-logo-box'>
            <img
              className='admin-primary-logo'
              src={logo_img}
              alt='FadezMDot'
              onClick={navigateAdminHome}
            />
          </div>
          <div className='admin-nav-burger'>
            <img
              className='burger-img'
              src={menu}
              alt='menu'
              onClick={onClickMenu}
            />
          </div>
        </div>
        <div
          className={`admin-nav-menu ${isMenuShown ? 'show-nav' : 'hide-nav'}`}
        >
          <ul className='admin-nav-menu-list u-list-style-none'>
            <li className='admin-nav-menu__item u-display-inline-block appointments-link'>
              <button
                className={`nav-menu__link u-text-decoration-none ${
                  location.pathname === '/admin/appointments'
                    ? 'active-link'
                    : ''
                }`}
                onClick={onClickAppointments}
              >
                Bokningar
              </button>
            </li>
            <li className='admin-nav-menu__item u-display-inline-block services-link'>
              <button
                className={`nav-menu__link u-text-decoration-none ${
                  location.pathname === '/admin/services' ? 'active-link' : ''
                }`}
                onClick={onClickServices}
              >
                Tjänster
              </button>
            </li>
            <li className='admin-nav-menu__item u-display-inline-block services-link'>
              <button
                className={`nav-menu__link u-text-decoration-none ${
                  location.pathname === '/admin/hours' ? 'active-link' : ''
                }`}
                onClick={onClickHours}
              >
                Öppettider
              </button>
            </li>
            <li className='admin-nav-menu__item u-display-inline-block signout-link'>
              <button
                className={`nav-menu__link u-text-decoration-none`}
                onClick={onClickSignOut}
              >
                Logga ut
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AdminNavbar
