import { createSlice } from '@reduxjs/toolkit'

const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  reducers: {
    servicesLoading(state, _) {
      state.loading = true
      state.error = null
    },
    servicesReceived(state, action) {
      state.loading = false
      state.data = action.payload
    },
    servicesFailed(state, action) {
      state.loading = false
      state.error = action.payload
    },
    deleteService(state, action) {
      const { id } = action.payload
      const index = state.data.findIndex(svc => svc.id === id)
      if (index !== -1) {
        const data = state.data[index]
        state.data = state.data.filter(svc => svc.id !== data.id)
      }
    },
  },
})

export const {
  servicesLoading,
  servicesReceived,
  servicesFailed,
  deleteService,
} = servicesSlice.actions
export default servicesSlice.reducer
