import { React } from 'react'
import Timeslot from './timeslot'

function TimeslotList({ timeslots, selectedTimeslot, handleSelectTimeslot }) {
  const onTimeslotClickHandle = timeslot => {
    handleSelectTimeslot(timeslot)
  }

  return (
    <div className='timeslot-list'>
      {timeslots
        .filter(timeslot => timeslot.isAvailable)
        .map(timeslot => (
          <Timeslot
            key={timeslot.slot}
            title={timeslot.slot}
            selected={selectedTimeslot === timeslot.slot}
            available={timeslot.isAvailable}
            onClick={() => onTimeslotClickHandle(timeslot)}
          />
        ))}
    </div>
  )
}

export default TimeslotList
