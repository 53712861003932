import { ServicesReference } from '../../services/DatabaseService'
import {
  servicesLoading,
  servicesReceived,
  servicesFailed,
  deleteService,
} from '../reducers/servicesReducer'

export const fetchServices = () => async dispatch => {
  try {
    dispatch(servicesLoading())
    const data = await ServicesReference.getAll()
    dispatch(servicesReceived(data))
  } catch (e) {
    dispatch(servicesFailed(e))
  }
}

export const removeService = id => async dispatch => {
  try {
    await ServicesReference.remove(id)
    dispatch(deleteService({ id: id }))
  } catch (e) {
    console.log(e)
  }
}
