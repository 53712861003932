import { React, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { auth } from '../../../../firebase'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import FormGroup from '../../../boka/form/components/formGroup'
import { ServicesReference } from '../../../../services/DatabaseService'

const ServicePage = () => {
  const { serviceId } = useParams()
  const navigate = useNavigate()
  const [service, setService] = useState({
    name: '',
    duration: '',
    price: '',
  })

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/admin/signin')
      }
    })
    return () => unsubscribe()
  }, [navigate])

  useEffect(() => {
    const getService = async () => {
      if (serviceId) {
        try {
          const doc = await ServicesReference.get(serviceId)
          setService({
            name: doc.data().name,
            duration: doc.data().duration,
            price: parseInt(doc.data().price),
          })
        } catch (error) {
          console.log('Error getting document:', error)
        }
      }
    }
    getService()
  }, [serviceId])

  const handleAddService = event => {
    event.preventDefault()
    if (
      service.name.trim() === '' ||
      service.duration.trim() === '' ||
      service.price.toString().trim() === ''
    ) {
      return
    }
    const newService = { ...service, price: `${service.price} kr` }
    if (serviceId) {
      updateServiceInDB(serviceId, newService)
    } else {
      addServiceToDB(newService)
    }
  }

  const addServiceToDB = async service => {
    try {
      await ServicesReference.create(service)
      navigate('/admin/services')
    } catch (error) {
      console.error('Error adding document: ', error)
    }
  }

  const updateServiceInDB = async (serviceId, service) => {
    try {
      await ServicesReference.update(serviceId, service)
      navigate('/admin/services')
    } catch (error) {
      console.error('Error updating document: ', error)
    }
  }

  return (
    <PageLayout
      title={serviceId ? 'REDIGERA TJÄNST' : 'LÄGG TILL NY TJÄNST'}
      admin={true}
    >
      <form className='form'>
        <div className='form-box'>
          <FormGroup title={'Namn'}>
            <input
              type='text'
              className='form__input'
              placeholder='Namn'
              id='Namn'
              value={service.name}
              onChange={e => setService({ ...service, name: e.target.value })}
              required
            />
          </FormGroup>
          <FormGroup title={'Interval'}>
            <select
              id='Interval'
              className='form__input'
              value={service.duration}
              onChange={e =>
                setService({ ...service, duration: e.target.value })
              }
            >
              <option value=''>Välj</option>
              <option value='15'>15 min</option>
              <option value='30'>30 min</option>
              <option value='45'>45 min</option>
              <option value='60'>1 h</option>
            </select>
          </FormGroup>
          <FormGroup title={'Pris'}>
            <input
              type='number'
              className='form__input'
              placeholder='Pris'
              id='Pris'
              value={service.price}
              onChange={e => setService({ ...service, price: e.target.value })}
              required
            />
          </FormGroup>
        </div>
        <div className='form-btn-box'>
          <div className='form__group'>
            <button className='form-btn' onClick={handleAddService}>
              {serviceId ? 'Redigera' : 'Lägg till'}
            </button>
          </div>
        </div>
      </form>
    </PageLayout>
  )
}

export default ServicePage
