import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Navbar from '../../../layouts/navbar/navbar'
import Calendar from './components/calendar'
import TimeslotList from './components/timeslotlist'
import './style.css'
import { format } from 'date-fns'
import Timeslotter from './Timeslotter'
import { formatInTZ } from '../../../utils/date-format'
import { useDispatch, useSelector } from 'react-redux'
import { fetchClientAppointments } from '../../../redux/actions/appointmentsActions'
import { fetchHours } from '../../../redux/actions/hoursActions'

const AppointmentBookingPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedDate, setSelectedDate] = useState(null)
  const [availableTimeSlots, setAvailableTimeSlots] = useState([])
  const [selectedTimeslot, setSelectedTimeslot] = useState(null)
  const [isClosed, setIsClosed] = useState(null)

  const hours = useSelector(state => state.hours.data)
  const appointments = useSelector(state => state.appointments.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchHours())
    dispatch(fetchClientAppointments(new Date(), '>='))
  }, [dispatch])

  const handleDateSelect = date => {
    setSelectedDate(date)
    generateTimeslots(date)
  }

  const generateTimeslots = date => {
    const day = format(date, 'EEEE').toLowerCase()
    const selectedHour = hours.filter(hour => hour.id === day)[0]
    const start = selectedHour.open
    const end = selectedHour.close

    if (start !== 'closed' && end !== 'closed') {
      setIsClosed(false)

      const serviceDuration = parseInt(location.state.service.duration)
      const timeSlotter = new Timeslotter(date, start, end, serviceDuration)
      const timeSlots = timeSlotter.getTimeSlots(appointments)

      setAvailableTimeSlots(timeSlots)
      setSelectedTimeslot(null)
    } else {
      setIsClosed(true)
      setAvailableTimeSlots([])
      setSelectedTimeslot(null)
    }
  }

  const handleSelectTimeslot = timeslot => {
    setSelectedTimeslot(timeslot.slot)
  }

  const handleBookAppointment = () => {
    const service = location.state.service
    const formattedDate = formatInTZ(selectedDate, 'yyyy-MM-dd')
    const startTime = new Date(`${formattedDate}T${selectedTimeslot}`)
    const endTime = new Date(startTime.getTime() + service.duration * 60 * 1000)
    const formattedEndTime = formatInTZ(endTime, 'HH:mm')

    navigate('/boka/form', {
      state: {
        service: service,
        date: selectedDate,
        slot: {
          start: selectedTimeslot,
          end: formattedEndTime,
        },
      },
    })
  }

  return (
    <div className='appointment-booking-page'>
      <Navbar />
      <div className='page-container'>
        <Calendar handleDateSelect={handleDateSelect} />
        <TimeslotList
          timeslots={availableTimeSlots}
          selectedTimeslot={selectedTimeslot}
          handleSelectTimeslot={handleSelectTimeslot}
        />
        <button
          className={`btn ${selectedTimeslot === null ? 'hide-btn' : ''}`}
          onClick={handleBookAppointment}
        >
          NÄSTA
        </button>
        <div
          className={`closed-view u-text-align-center ${
            isClosed === null || isClosed === false ? 'hide-btn' : ''
          }`}
        >
          Stängd
        </div>
      </div>
    </div>
  )
}

export default AppointmentBookingPage
