import { useState } from 'react'
import {
  addDays,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  isBefore,
  startOfDay,
} from 'date-fns'
import { formatInTZ } from '../../../../utils/date-format'

const Calendar = ({ handleDateSelect }) => {
  const [currentMonth] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)

  const onDateClickHandle = date => {
    setSelectedDate(date)
    handleDateSelect(date)
  }

  const renderHeader = () => {
    const dateFormat = 'MMMM yyyy'
    return (
      <div className='header row flex-middle'>
        <div className='col col-center'>
          <span>{formatInTZ(currentMonth, dateFormat)}</span>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const dateFormat = 'EEE'
    const days = []
    let startDate = startOfWeek(new Date(), { weekStartsOn: 0 }) // set start date to this week's Sunday
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='col col-center' key={i}>
          {formatInTZ(addDays(startDate, i), dateFormat)}
        </div>
      )
    }
    return <div className='days row'>{days}</div>
  }

  const renderCells = () => {
    const startDate = startOfWeek(new Date(), { weekStartsOn: 0 }) // set start date to this week's Sunday
    const endDate = lastDayOfWeek(startDate, { weekStartsOn: 0 })
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = formatInTZ(day, dateFormat)
        const cloneDay = day
        const isDisabled = isBefore(day, startOfDay(new Date())) // check if day is before today
        days.push(
          <div
            className={`col cell ${
              isSameDay(day, selectedDate) ? 'selected' : ''
            } ${isDisabled ? 'disabled' : ''}`}
            key={day}
            onClick={() => {
              if (!isDisabled) {
                onDateClickHandle(cloneDay)
              }
            }}
          >
            <span className='number'>{formattedDate}</span>
            <span className='bg'>{formattedDate}</span>
          </div>
        )
        day = addDays(day, 1)
      }

      rows.push(
        <div className='row' key={day}>
          {days}
        </div>
      )
      days = []
    }
    return <div className='body'>{rows}</div>
  }

  return (
    <div className='calendar'>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  )
}

export default Calendar
