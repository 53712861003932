import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../../firebase'
import { FiEdit } from 'react-icons/fi'
import './style.css'
import PageLayout from '../../../../layouts/pageLayout/pageLayout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHours } from '../../../../redux/actions/hoursActions'

const Hours = () => {
  const navigate = useNavigate()
  const isLoading = useSelector(state => state.hours.loading)
  const hours = useSelector(state => state.hours.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchHours())
    const signIn = () => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (!user) {
          navigate('/admin/signin')
        }
      })
      return () => unsubscribe()
    }
    signIn()
  }, [dispatch, navigate])

  const handleEdit = hourId => {
    navigate(`/admin/hours/${hourId}`)
  }

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    <PageLayout title={'ÖPETTIDER'} admin={true}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {hours.map(hr => (
            <div key={hr.id} className='hour-container'>
              <p className='day-name'>{capitalizeFirstLetter(hr.id)}</p>
              <div className='hours-box'>
                <p className='time-p'>
                  {hr.open === 'closed' ? 'stängd' : `${hr.open} - ${hr.close}`}
                </p>
                <div className='actions-list'>
                  <button
                    className='action-btn'
                    onClick={() => handleEdit(hr.id)}
                  >
                    <FiEdit className='action-btn-icon' />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </PageLayout>
  )
}

export default Hours
