import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Masthead from './components/masthead'
import Testimonials from './components/testimonials'
import Contact from './components/contact'
import Footer from './components/footer'
import './style.css'

const Home = () => {
  const navigate = useNavigate()

  function handleBookApptButton() {
    navigate('/boka/tjanster')
  }

  return (
    <>
      <Helmet>
        ‍<title>Fadezmdot - Din barberare i kristianstad</title>‍
        <meta
          name='description'
          content='Boka tid hos din favorit barberare i kristianstad'
        />
        <meta
          property='og:title'
          content='Fadezmdot - Din barberare i kristianstad'
        />
        <meta
          property='og:description'
          content='Boka tid hos din favorit barberare i kristianstad'
        />
        <meta property='og:locale' content='sv_SE' />
        <meta property='og:type' content='website' />
      </Helmet>
      <div className='homepage'>
        <Masthead onClick={handleBookApptButton} />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default Home
