import { firestore } from '../firebase'

class DatabaseService {
  collection

  constructor(collectionName) {
    this.collection = firestore.collection(collectionName)
  }

  getAll = async () => {
    const snapshot = await this.collection.get()
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
  }

  getAllWhere = async (field, operator, value) => {
    const snapshot = await this.collection.where(field, operator, value).get()
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
  }

  getAllWhereAndOrderBy = async (field, operator, value, orderBy) => {
    const snapshot = await this.collection
      .where(field, operator, value)
      .orderBy(orderBy)
      .get()
    return snapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      }
    })
  }

  get = async id => {
    return await this.collection.doc(id).get()
  }

  create = async data => {
    return await this.collection.add(data)
  }

  remove = async id => {
    return await this.collection.doc(id).delete()
  }

  update = async (id, data) => {
    return await this.collection.doc(id).update(data)
  }
}

export const ServicesReference = new DatabaseService('services')
export const HoursReference = new DatabaseService('hours')
export const AppointmentsReference = new DatabaseService('appointments')
