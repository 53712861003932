import React from 'react'

const Service = ({ service, onClick }) => {
  return (
    <div className='service-box u-display-flex u-justify-content-space-between'>
      <div className='service-info'>
        <h3 className='service-info__name'>{service.name}</h3>
        <div className='u-display-flex'>
          <p className='service-info_duration'>{service.duration} minuter</p>
          <p>,&nbsp;</p>
          <p className='service_info__price'>{service.price}</p>
        </div>
      </div>
      <div className='cta-box'>
        <button className='service-cta' onClick={() => onClick(service)}>
          Boka
        </button>
      </div>
    </div>
  )
}

export default Service
