import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../../firebase'
import './style.css'

function AdminSignIn() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault()
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        navigate('/admin/services')
      })
      .catch(error => {
        console.error('Error signing in with password and email', error)
      })
  }

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget

    if (name === 'userEmail') {
      setEmail(value)
    } else if (name === 'userPassword') {
      setPassword(value)
    }
  }

  return (
    <div className='signin-page'>
      <div className='sigin-container'>
        <h1 className='signin-header'>Admin</h1>
        <div className='signin-form-container'>
          <form className='siginin-form'>
            <label htmlFor='userEmail'>E-post:</label>
            <input
              type='email'
              name='userEmail'
              value={email}
              onChange={event => onChangeHandler(event)}
            />
            <label htmlFor='userPassword'>Lösenord:</label>
            <input
              type='password'
              name='userPassword'
              value={password}
              onChange={event => onChangeHandler(event)}
            />
            <button
              onClick={event => {
                signInWithEmailAndPasswordHandler(event, email, password)
              }}
            >
              Logga in
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AdminSignIn
