import AdminNavbar from '../adminNavbar/AdminNavbar'
import Navbar from '../navbar/navbar'
import './style.css'

const PageLayout = ({ title, children, admin = false, headerButton }) => {
  return (
    <>
      {admin === true ? <AdminNavbar /> : <Navbar />}
      <div className='page-container'>
        <div className='header'>
          <h2 className='page-title '>{title}</h2>
          {headerButton}
        </div>
        {children}
      </div>
    </>
  )
}

export default PageLayout
