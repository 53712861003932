import { combineReducers } from 'redux'
import appointmentsReducer from './appointmentsReducer'
import servicesReducer from './servicesReducer'
import hoursReducer from './hoursReducer'

const rootReducer = combineReducers({
  appointments: appointmentsReducer,
  services: servicesReducer,
  hours: hoursReducer,
})

export default rootReducer
