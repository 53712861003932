import logo from '../assets/images/logo.png'

export const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const logo_img = logo

export const masthead = {
  title: 'FADEZ.MDOT',
  subtitle: 'Din barberare i Kristianstad',
  cta: 'Boka tid',
}

export const testimonials = [
  {
    testimonial: 'Bra utförd klippning väldigt serviceminded',
    name: 'Leo B',
  },
  {
    testimonial: 'Trevligt bemötande! Snabbt och smidigt utfört!',
    name: 'Gabriel K',
  },
  {
    testimonial:
      'Grymt snabb, fick till frisyren exakt som jag ville utan problem.',
    name: 'Ali A',
  },
]

export const location = {
  address: 'Tegelbruksvägen 2',
  postcode: '291 36',
  city: 'Kristianstad',
  position: {
    lat: 56.04908,
    lon: 14.15516,
  },
}

export const socialLinks = {
  instagram: 'https://www.instagram.com/fadez.mdot/',
  tiktok: 'https://www.tiktok.com/@fadez.mdot/',
}
