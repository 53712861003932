import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import sv from 'date-fns/locale/sv'
import Navbar from '../../../layouts/navbar/navbar'
import checkmark from '../../../assets/images/check-mark-1.svg'
import './style.css'

const Confirmation = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const onClickHandle = () => {
    navigate('/')
  }

  return (
    <div className='confirmation-page'>
      <Navbar />
      <div className='page-container'>
        <img className='checkmark-img' src={checkmark} alt='check-mark' />
        <h4>Din bokning är bekräftad</h4>
        <p>
          {format(location.state.date, 'cccc dd MMM yyyy', { locale: sv })}, kl{' '}
          {location.state.slot.start}
        </p>
        <button className='done-cta' onClick={onClickHandle}>
          Klar
        </button>
      </div>
    </div>
  )
}

export default Confirmation
