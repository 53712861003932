import { HoursReference } from '../../services/DatabaseService'
import {
  hoursLoading,
  hoursReceived,
  hoursFailed,
  hourUpdate,
} from '../reducers/hoursReducer'

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const fetchHours = () => async dispatch => {
  try {
    dispatch(hoursLoading())
    const data = await HoursReference.getAll()
    const sorted = data.sort((a, b) => {
      const dayNumberA = weekdays.indexOf(a.id)
      const dayNumberB = weekdays.indexOf(b.id)
      return dayNumberA - dayNumberB
    })
    dispatch(hoursReceived(sorted))
  } catch (e) {
    dispatch(hoursFailed(e))
  }
}

export const updateHour = (id, data) => async dispatch => {
  try {
    await HoursReference.update(id, data)
    dispatch(hourUpdate({ id, data }))
  } catch (e) {
    console.log(e)
  }
}
