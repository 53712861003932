import React, { useState } from 'react'
import Navbar from '../../layouts/navbar/navbar'
import { formatInTZ } from '../../utils/date-format'
import './style.css'
import { AppointmentsReference } from '../../services/DatabaseService'

const AppointmentCancelPage = () => {
  const [code, setCode] = useState('')
  const [mssg, setMssg] = useState('')
  const cancelTimeInHour = 6

  const handleCancellation = async event => {
    event.preventDefault()

    if (code.trim() === '') {
      setMssg('Du måste ange avbokningskoden')
      return
    }

    await validateAndCancelAppt()
  }

  const validateAndCancelAppt = async () => {
    try {
      const doc = await AppointmentsReference.get(code)
      const appt = doc.data()

      const apptDate = formatInTZ(appt.date.toDate(), 'yyyy-MM-dd')
      const apptStart = new Date(`${apptDate}T${appt.slot.start}:00`)

      const currentDate = new Date()
      const newDate = new Date(
        currentDate.getTime() + cancelTimeInHour * 60 * 60 * 1000
      )

      if (newDate >= apptStart) {
        setMssg(
          `Avbokning sker max ${cancelTimeInHour} timmar innan bokad tid.`
        )
      } else {
        deleteAppointment()
        setCode('')
      }
    } catch (error) {
      setMssg('Vi kan inte hitta din bokning!')
      console.error(error.message)
    }
  }

  const deleteAppointment = async () => {
    try {
      await AppointmentsReference.remove(code)
      setMssg('Din tid har avbokats.')
    } catch (error) {
      setMssg('Något fel har inträffat. försök igen!')
      console.error(
        `Error deleting appointment with ID ${code}: ${error.message}`
      )
    }
  }

  return (
    <div className='appointment-cancel-page'>
      <Navbar />
      <div className='page-container'>
        <form className='cancel-form'>
          <h2 className='section-title u-margin-bottom-medium'>AVBOKA</h2>
          <div className='cancel-form-message'>{mssg}</div>
          <div className='cancel-form__group'>
            <input
              type='text'
              className='cancel-form__input'
              placeholder='Avbokningskoden'
              id='code'
              value={code}
              onChange={e => setCode(e.target.value)}
              required
            />
          </div>
          <button
            className='cancel-button'
            onClick={e => handleCancellation(e)}
          >
            Bekräfta avbokning
          </button>
        </form>
      </div>
    </div>
  )
}

export default AppointmentCancelPage
