import React from 'react'
import { format } from 'date-fns'
import sv from 'date-fns/locale/sv'
import './style.css'

const Appointment = ({ appointment, serviceName, handleApptCancelation }) => {
  return (
    <div className='admin-appointment'>
      <div className='client-name'>
        Namn: {appointment.client.firstname} {appointment.client.lastname}
      </div>
      <div className='client-phone_number'>
        Tel: {appointment.client.phoneNumber}
      </div>
      <div className='appt-service'>
        Tjänst: {appointment.serviceName ?? serviceName}
      </div>
      <div className='appt-date'>
        Datum:{' '}
        {format(appointment.date.toDate(), 'cccc yyyy-MM-dd', { locale: sv })}
      </div>
      <div className='appt-slot'>
        Tid: {appointment.slot.start} - {appointment.slot.end}
      </div>
      <button
        className='cancel-btn'
        onClick={() => handleApptCancelation(appointment.id)}
      >
        Avboka
      </button>
    </div>
  )
}

export default Appointment
