import { createSlice } from '@reduxjs/toolkit'

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  reducers: {
    appointmentsLoading(state, _) {
      state.loading = true
      state.error = null
    },
    appointmentsReceived(state, action) {
      state.loading = false
      state.data = action.payload
    },
    appointmentsFailed(state, action) {
      state.loading = false
      state.error = action.payload
    },
    appointmentDelete(state, action) {
      const { id } = action.payload
      const index = state.data.findIndex(appt => appt.id === id)
      if (index !== -1) {
        const appt = state.data[index]
        state.data = state.data.filter(a => a.id !== appt.id)
      }
    },
  },
})

export const {
  appointmentsLoading,
  appointmentsReceived,
  appointmentsFailed,
  appointmentDelete,
} = appointmentsSlice.actions
export default appointmentsSlice.reducer
